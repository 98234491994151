import ptBrLocale from 'date-fns/locale/pt-BR';
import enUsLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { responseMessages } from './serverMessages';
import { yup } from 'src/utils/definitionsYup';

import { enUS, esES, ptBR } from '@mui/x-date-pickers/locales';

export const locale = {
    en: enUsLocale,
    es: esLocale,
    pt: ptBrLocale,
}

export const dateLocale = {
    en: enUS,
    es: esES,
    pt: ptBR,
}

export const supportedLanguages = {
    en: 'English',
    es: 'Español',
    pt: 'Português',
};

const invites = {
    en: {
        expires: 'Expires on',
        issuedOn: 'Issued',
        inviteHeader: 'The following invites will be ',
        provisionHandles: 'Provision User Handles',
        invites: 'Invites',
        inviteType: 'Invite Type',
        inviteInfo: 'Invite Info',
        completeInvite: 'Finalize Invite',
        sendAnotherInvite: 'Send Another Invite',
        inviteLanguage: 'Choose Invite Language',
        chosenInviteLanguage: 'Chosen Invite Language',
        matchUser: 'Invited users must create accounts matching their invited address',
        matchUserNegative: 'Users can sign-up with any e-mail address',
        directSigninTag: 'Automatic Sign-in',
        directSignin: 'Invited users will sign-in automatically after creating account',
        directSigninNotice: 'If selected, invited users will have to enter the code received on the invite email in the Create Account Form on the app',
        directSigninNegative: 'Invited users will have to verify their e-mail accounts before signing in',
        enterEmails: 'Type your list of invited e-mails (one per line)',
        enterHandles: 'Type your list of invited handles (one per line)',
        processList: 'Process List',
        startOver: 'Start Over',
        processed: 'Processed',
        validEmails: 'valid e-mails',
        validHandles: 'valid handles',
        notEnoughLicenses: 'Not enough available licenses - Reduce number of invites',
        reviewAndSend: 'Review and Send',
        reviewAndProvision: 'Review and Provision',
        reviewInvite: 'Review your invite',
        sendInvites: 'Send Invites',
        typeOfInvite: 'How would you like to onboard users?',
        sendInvitesViaEmail: 'Send Invites via e-mail',
        viaEmailDescription: '<b>Send Invites via e-mail</b><br />Users will receive an e-mail with a code.',
        viaHandleProvisioning: '<b>Provision User Handles</b><br />Define a list of pre-approved user handles.',
        assignedTeam: 'Assigned Company: ',
        assignedLicense: 'Chosen License Pack: ',
        listOfEmailsToBeInvited: 'List of Invited User E-mails',
        listOfHandlesToBeInvited: 'List of Invited User Handles',
        handle: 'User Handle',
        teamSlugNotice: 'User Handles will incorporate the company identifier',
        code: 'Code',
        match: 'Match',
        resendInvite: 'Resend Invite',
        inviteDelete: 'deleted',
        inviteResend: 'resent',
    },
    pt: {
        expires: 'Expira em',
        issuedOn: 'Enviado',
        inviteHeader: 'Os seguintes convites serão ',
        inviteDelete: 'removidos',
        inviteResend: 'reenviados',
        match: 'Correspondência',
        code: 'Código',
        provisionHandles: 'Provisionar Identificador de Usuário',
        invites: 'Convites',
        inviteType: 'Tipo de Convite',
        inviteInfo: 'Informações do Convite',
        completeInvite: 'Finalizar Convite',
        sendAnotherInvite: 'Enviar Outro Convite',
        inviteLanguage: 'Escolha a Língua do Convite',
        chosenInviteLanguage: 'Língua Escolhida',
        matchUser: 'Exigir que o e-mail do convite seja o mesmo que das contas criadas',
        matchUserNegative: 'Usuários podem se cadastrar com qualquer e-mail',
        directSigninTag: 'Acesso automático',
        directSignin: 'Usuários convidados poderão acessar diretamente sua conta após criação',
        directSigninNotice: 'Se selecionado, os usuários deverão inserir o código recebido no email de convite no formulário de criação de conta no app',
        directSigninNegative: 'Todos usuários deverão verificar suas contas de e-mail',
        enterEmails: 'Digite a sua lista de e-mails convidados (um por linha)',
        enterHandles: 'Digite sua lista de identificadores convidados (um por linha)',
        processList: 'Processar Lista',
        startOver: 'Recomeçar',
        processed: 'Processados',
        validEmails: 'valid e-mails',
        validHandles: 'identificadores válidos',
        notEnoughLicenses: 'Não há licensas disponíveis suficientes - Reduza o número de convites',
        reviewAndSend: 'Revisar e Enviar',
        reviewAndProvision: 'Revisar e Provisionar',
        sendInvites: 'Mandar Convites',
        reviewInvite: 'Revise seu convite',
        typeOfInvite: 'Como você gostaria de convidar usuários?',
        sendInvitesViaEmail: 'Mandar convites via e-mail',
        viaEmailDescription: '<b>Send Invites via e-mail</b><br />Users will receive an e-mail with a code.',
        viaHandleProvisioning: '<b>Provision User Handles</b><br />Define a list of pre-approved user handles.',
        assignedTeam: 'Time escolhido: ',
        assignedLicense: 'Pacote de Licença escolhido: ',
        listOfEmailsToBeInvited: 'Lista de E-mails de Usuários Convidados',
        listOfHandlesToBeInvited: 'Lista de Identificadores de Usuário Convidados',
        handle: 'Identificador de Usuário',
        teamSlugNotice: 'Os Identificadores de Usuário incorporarão o identificador do time',
        resendInvite: 'Re-enviar Convite',
    },
    es: {
        expires: 'Expira el',
        issuedOn: 'Enviado',
        inviteHeader: 'Las siguientes invitaciones serán ',
        provisionHandles: 'Provisión de identificadores de usuario',
        invites: 'Invitaciones',
        inviteType: 'Tipo de invitación',
        inviteInfo: 'Información de la invitación',
        completeInvite: 'Completar invitación',
        sendAnotherInvite: 'Enviar otra invitación',
        inviteLanguage: 'Elegir idioma de la invitación',
        chosenInviteLanguage: 'Idioma elegido para la invitación',
        matchUser: 'Los usuarios invitados deben crear cuentas que coincidan con su dirección de invitación',
        matchUserNegative: 'Los usuarios pueden registrarse con cualquier dirección de correo electrónico',
        directSigninTag: 'Inicio de sesión automático',
        directSignin: 'Los usuarios invitados iniciarán sesión automáticamente después de crear la cuenta',
        directSigninNotice: 'Si es seleccionado, los usuarios deberán ingresar el código recibido en el correo de invitación en el formulario de creación de cuenta en la aplicación',
        directSigninNegative: 'Los usuarios invitados deberán verificar sus cuentas de correo electrónico antes de iniciar sesión',
        enterEmails: 'Escriba su lista de correos electrónicos invitados (uno por línea)',
        enterHandles: 'Escriba su lista de identificadores invitados (uno por línea)',
        processList: 'Procesar lista',
        startOver: 'Empezar de nuevo',
        processed: 'Procesado',
        validEmails: 'correos electrónicos válidos',
        validHandles: 'identificadores válidos',
        notEnoughLicenses: 'No hay suficientes licencias disponibles - Reduzca el número de invitaciones',
        reviewAndSend: 'Revisar y enviar',
        reviewAndProvision: 'Revisar y proveer',
        reviewInvite: 'Revisar su invitación',
        sendInvites: 'Enviar invitaciones',
        typeOfInvite: '¿Cómo le gustaría incorporar a los usuarios?',
        sendInvitesViaEmail: 'Enviar invitaciones por correo electrónico',
        viaEmailDescription: '<b>Enviar invitaciones por correo electrónico</b><br />Los usuarios recibirán un correo electrónico con un código.',
        viaHandleProvisioning: '<b>Provisión de identificadores de usuario</b><br />Defina una lista de identificadores de usuario preaprobados.',
        assignedTeam: 'Equipo asignado: ',
        assignedLicense: 'Paquete de licencias elegido: ',
        listOfEmailsToBeInvited: 'Lista de correos electrónicos de usuarios invitados',
        listOfHandlesToBeInvited: 'Lista de identificadores de usuario invitados',
        handle: 'Identificador de usuario',
        teamSlugNotice: 'Los identificadores de usuario incorporarán el identificador del equipo',
        code: 'Código',
        match: 'Coincidencia',
        resendInvite: 'Reenviar invitación',
        inviteDelete: 'eliminada',
        inviteResend: 'reenviada',
    }
}

const domains = {
    en: {
        domainWhitelist: 'Domain Whitelist',
        createDomainAssociation: 'Register Domain',
        registerDomainAssociations: 'Register Associations',
        manageDomains: 'Manage Team-Domain Associations',
        editDomainAssociation: 'Edit Domain Associations',
        domains: 'Domains',
        domain: 'Domain',
        domainList: 'Domain Associations',
        noDomainsRegistered: 'No domains have been registered for this team',
        enterDomain: 'Enter your domain',
        deleteAllDomainsForTeam: 'All domains registered to the following team will be',
        domainsAssociatedWith: 'Domains associated with team',
    },
    pt: {
        domainWhitelist: 'Domínios Aprovados',
        createDomainAssociation: 'Registrar Domínio',
        registerDomainAssociations: 'Registrar Associações',
        manageDomains: 'Gerenciar Associações Time-Domínio',
        editDomainAssociation: 'Editar Associações com Domínios',
        domain: 'Domínio',
        domains: 'Domínios',
        domainList: 'Associação de Domínios',
        noDomainsRegistered: 'Nenhum domínio foi registrado para este time',
        enterDomain: 'Digite seu domínio',
        deleteAllDomainsForTeam: 'Todos domínios registrados para o time abaixo serão',
        domainsAssociatedWith: 'Domínios associados com time',
    },
    es: {
        domainWhitelist: 'Lista blanca de dominios',
        createDomainAssociation: 'Registrar dominio',
        registerDomainAssociations: 'Registrar asociaciones',
        manageDomains: 'Gestionar asociaciones equipo-dominio',
        editDomainAssociation: 'Editar asociaciones con dominios',
        domains: 'Dominios',
        domain: 'Dominio',
        domainList: 'Asociaciones de dominio',
        noDomainsRegistered: 'No se han registrado dominios para este equipo',
        enterDomain: 'Escriba su dominio',
        deleteAllDomainsForTeam: 'Todos los dominios registrados para el siguiente equipo serán',
        domainsAssociatedWith: 'Dominios asociados con el equipo',
    }
}

const licenses = {
    en: {
        distributeLicenses: 'Distribute licenses',
        activateLicenseFill: 'Please copy and paste the license token provided to you',
        noLicensesAvailable: 'No Licenses Available',
        createLicenses: 'Create new license packs',
        licenses: 'Licences',
        license: 'License',
        licenseID: 'License ID',
        licenseType: 'Type',
        extendLicense: 'Extend License Pack',
        expireLicense: 'Expire License Pack',
        licenseStatus: 'License Status',
        licenseExpired: 'Licence Expired',
        licenseExpiration: 'Licence Expiration',
        licenseActivateToken: 'Activate Licenses',
        licenseChose: 'Choose license pack',
        licenseCount: 'Choose number of licenses',
        licenseExtend: '# of days from today',
        licenseValid: 'Valid license',
        licenseNearExpiraton: 'License about to expire',
        licenseValidUntil: 'License valid until',
        unlicensed: 'Unlicensed',
        licenseGauge: 'Users / Invites / Available',
        licenseHeader: 'The following license will be ',
        identifier: 'Identifier',
    },
    pt: {
        distributeLicenses: 'Distribua as licenças',
        activateLicenseFill: 'Por favor, copie e cole o token de licença fornecido a você',
        noLicensesAvailable: 'Nenhuma Licença Disponível',
        createLicenses: 'Criar novos pacotes de licença',
        licenses: 'Licenças',
        license: 'Licença',
        licenseID: 'ID',
        licenseType: 'Tipo',
        licenseStatus: 'Status de Licenças',
        licenseActivateToken: 'Ativar Licenças',
        licenseExpired: 'Licença expirada',
        licenseExpiration: 'Expiração da Licença',
        licenseValid: 'Licença válida',
        licenseChose: 'Escolher pacote de licença',
        licenseCount: 'Escolher quantidade de licenças',
        licenseExtend: '# de dias a partir de hoje',
        extendLicense: 'Extender pacote de licença',
        expireLicense: 'Expirar pacote de licença',
        licenseNearExpiraton: 'Licença perto de expirar',
        licenseValidUntil: 'Licença válida até',
        unlicensed: 'Sem licenças',
        licenseGauge: 'Usuários / Convites / Disponível',
        licenseHeader: 'A seguinte licença será',
        identifier: 'Identificador',
    },
    es: {
        distributeLicenses: 'Distribuya las licencias',
        activateLicenseFill: 'Por favor, copie y pegue el token de licencia que se le proporcionó',
        noLicensesAvailable: 'No hay licencias disponibles',
        createLicenses: 'Crear nuevos paquetes de licencias',
        licenses: 'Licencias',
        license: 'Licencia',
        licenseID: 'ID de licencia',
        licenseType: 'Tipo',
        extendLicense: 'Estender paquete de licencias',
        expireLicense: 'Expirar paquete de licencias',
        licenseStatus: 'Estado de la licencia',
        licenseExpired: 'Licencia expirada',
        licenseExpiration: 'Expiración de la licencia',
        licenseActivateToken: 'Activar licencias',
        licenseChose: 'Elegir paquete de licencia',
        licenseCount: 'Elegir número de licencias',
        licenseExtend: '# de dias a partir de oje',
        licenseValid: 'Licencia válida',
        licenseNearExpiraton: 'Licencia a punto de expirar',
        licenseValidUntil: 'Licencia válida hasta',
        unlicensed: 'Sin licencia',
        licenseGauge: 'Usuarios / Invitaciones / Disponibles',
        licenseHeader: 'La siguiente licencia será ',
        identifier: 'Identificador',
    }
}

const teams = {
    en: {
        teamName: 'Company name',
        noTeamsAvailable: 'No Companies Available',
        noUsersAvailable: 'No Users Available',
        teamAndLicense: 'Company and License',
        teamAndLicense2: 'Select Company and License',
        slug: 'Identifier',
        azureAD: 'Azure AD Synchronization',
        certRevocationStrategy: 'Strategy for Checking a Certificate\'s Revocation Status',
        ocsp_only: 'OCSP Only',
        ocsp_crl: 'OCSP and CRL',
        disabledOCSP: 'Disabled (for debugging)',
        editTeamMembership: 'Edit Company Membership',
        editTeamTitle: 'Company Membership',
        editTeamSelect: 'Select companies',
        teamSettings: 'Company Custom Settings',
        editDefaultSettings: 'Click on the checkboxes below to override the default system settings',
        noTeamAdmin: 'This company is not currently managed by any administrator!',
        oauthClientID: 'OAuth client ID',
        oauthAuthority: 'OAuth authority',
        oauthDiscovery: 'OAuth discovery',
        oauthVersion: 'OAuth version',
        oauthTenantID: 'OAuth tenant ID',
        emailTheme: 'E-mail theme',
    },
    pt: {
        teamName: 'Nome do time',
        noTeamsAvailable: 'Nenhum Time Disponível',
        noUsersAvailable: 'Nenhum Usuário Disponível',
        teamAndLicense: 'Time e Licença',
        teamAndLicense2: 'Selecione Time e Licença',
        slug: 'Identificador',
        azureAD: 'Sincronização com Azure AD',
        certRevocationStrategy: 'Estratégia para checar o status de revogação de um certificado',
        ocsp_only: 'Apenas OCSP',
        ocsp_crl: 'OCSP e CRL',
        disabledOCSP: 'Desabilitado (para debugging)',
        editTeamMembership: 'Editar Acesso a Times',
        editTeamTitle: 'Acesso a times',
        editTeamSelect: 'Selecione times',
        teamSettings: 'Configurações Personalizadas do time',
        editDefaultSettings: 'Clique nas caixas abaixo para redefinir os padrões do sistema',
        noTeamAdmin: 'Este time atualmente não é gerenciado por nenhum administrador!',
        oauthClientID: 'OAuth client ID',
        oauthAuthority: 'OAuth authority',
        oauthDiscovery: 'OAuth discovery',
        oauthVersion: 'OAuth version',
        oauthTenantID: 'OAuth tenant ID',
        emailTheme: 'Tema dos E-mails',
    },
    es: {
        teamName: 'Nombre del equipo',
        noTeamsAvailable: 'No hay equipos disponibles',
        noUsersAvailable: 'No hay usuarios disponibles',
        teamAndLicense: 'Equipo y licencia',
        teamAndLicense2: 'Seleccionar nuevo equipo o licencia',
        slug: 'Identificador',
        azureAD: 'Sincronización con Azure AD',
        certRevocationStrategy: 'Estrategia para comprobar el estado de revocación del certificado',
        ocsp_only: 'Solo OCSP',
        ocsp_crl: 'OCSP y CRL',
        disabledOCSP: 'Deshabilitado (para depuración)',
        editTeamMembership: 'Editar membresía del equipo',
        editTeamTitle: 'Membresía del equipo',
        editTeamSelect: 'Seleccionar equipos',
        teamSettings: 'Configuraciones personalizadas del equipo',
        editDefaultSettings: 'Haga clic en las casillas a continuación para sobrescribir las configuraciones predeterminadas del sistema',
        noTeamAdmin: '¡Este equipo no es gestionado actualmente por ningún administrador!',
        oauthClientID: 'OAuth client ID',
        oauthAuthority: 'OAuth authority',
        oauthDiscovery: 'OAuth discovery',
        oauthVersion: 'OAuth version',
        oauthTenantID: 'OAuth tenant ID',
        emailTheme: 'E-mail theme',
    }
}

const users = {
    en: {
        appVersion: 'App version',
        os: 'Operating System',
        searchBy: 'Search by name or username',
        reInvited: 're-invited',
        resendInvite: 'Re-send invite',
        thirdPartyNiapDevice: 'Third-party SIP user',
        cellcryptApp: 'Cellcrypt device',
        terminateAcctHeader: 'Are you sure you want to terminate your account?',
        terminateAcctWarning: 'This action cannot be undone. You will be signed out automatically.',
        usersHeader: 'The following users will be ',
        userCreateTeamError: 'You must choose a company with available licenses',
        users: 'Users',
        manageUsers: 'Manage Users',
        userProfile: 'User Profile',
        usersCreate: 'Create Users',
        usersCreated: 'User created at',
        userCreate: 'Create User',
        usersList: 'List Users',
        userInfo: 'User Information',
        generateStrongPassword: 'Generate Strong Password',
        deviceWiped: 'Device Wiped!',
        aliases: 'Aliases',
        devices: 'Devices',
        remoteWipe: 'Remotely Wipe Device',
        remoteWiped: 'remotely wiped',
        noDevicesAvailable: 'No devices available',
        ecs: 'Enterprise Communication System User',
        emailOrIdentifier: 'E-mail or User Identifier'
    },
    pt: {
        appVersion: 'Versão do app',
        os: 'Sistema Operacional',
        searchBy: 'Procure por um nome ou identificador',
        users: 'Usuários',
        reInvited: 'convidados novamente',
        resendInvite: 'Re-enviar convite',
        thirdPartyNiapDevice: 'Usuário de Dispositivo SIP',
        cellcryptApp: 'Dispostivo Cellcrypt',
        terminateAcctHeader: 'Você tem certeza que deseja encerrar sua conta?',
        terminateAcctWarning: 'Essa ação não pode ser desfeita. Sua sessão será terminada imediatamente.',
        usersHeader: 'Os seguintes usuários serão',
        userCreateTeamError: 'Você deve escolher um time com licenças disponíveis!',
        manageUsers: 'Gerenciar usuários',
        userProfile: 'Perfil de usuário',
        usersCreate: 'Criar usuários',
        usersCreated: 'Criado em',
        userCreate: 'Criar usuário',
        usersList: 'Listar usuários',
        userInfo: 'Informação do usuário',
        generateStrongPassword: 'Gerar senha segura',
        deviceWiped: 'Dispositivo Limpo!',
        devices: 'Dispositivos',
        aliases: 'Codinomes',
        remoteWipe: 'Limpar Dispositivo Remotamente',
        remoteWiped: 'limpo remotamente',
        noDevicesAvailable: 'Nenhum dispositivo disponível',
        ecs: 'Usuário do Sistema de Comunicação Corporativa (ECS)',
        emailOrIdentifier: 'E-mail ou identificador de usuário'
    },
    es: {
        appVersion: 'Versión de la aplicación',
        os: 'Sistema Operativo',
        searchBy: 'Buscar por un nombre o identificador',
        users: 'Usuarios',
        reInvited: 'reinvitado',
        resendInvite: 'Reenviar invitación',
        thirdPartyNiapDevice: 'Usuario SIP de terceros',
        cellcryptApp: 'Dispositivo Cellcrypt',
        terminateAcctHeader: '¿Está seguro de que desea cancelar su cuenta?',
        terminateAcctWarning: 'Esta acción no se puede deshacer. Será desconectado automáticamente.',
        usersHeader: 'Los siguientes usuarios serán ',
        userCreateTeamError: 'Debe elegir un equipo con licencias disponibles',
        manageUsers: 'Gestionar usuarios',
        userProfile: 'Perfil del usuario',
        usersCreate: 'Crear usuarios',
        usersCreated: 'Usuario creado en',
        userCreate: 'Crear usuario',
        usersList: 'Lista de usuarios',
        userInfo: 'Información del usuario',
        generateStrongPassword: 'Generar contraseña segura',
        deviceWiped: '¡Dispositivo borrado!',
        aliases: 'Alias',
        devices: 'Dispositivos',
        remoteWipe: 'Borrado remoto del dispositivo',
        remoteWiped: 'borrado remotamente',
        noDevicesAvailable: 'No hay dispositivos disponibles',
        ecs: 'Usuario del Sistema de Comunicación Empresarial',
        emailOrIdentifier: 'E-mail o identificador de usuario'
    }
}

const dashboard = {
    en: {
        extendExistingLicensePacks: 'Extend existing license packs',
        dashLicenseInfo: 'License information',
        userStatus: 'User status',
        totalUsers: 'Total # of users',
        totalActive: 'Active',
        totalPending: 'Pending',
        totalBlocked: 'Blocked',
        deviceTypes: 'Device types',
        totalActivePacks: 'License packs',
        totalNumLicenses: 'Total # of licenses',
        nextToExpire: 'Next expiration date',
        totalInvitedUsers: 'User invites',
        totalUsedLicenses: 'Used licenses',
        totalAvailableLicenses: 'Available licenses',
    },
    pt: {
        extendExistingLicensePacks: 'Estender pacotes de licenças existentes',
        dashLicenseInfo: 'Informações sobre licenças',
        userStatus: 'Status dos usuários',
        totalUsers: '# total de usuários',
        totalActive: 'Ativo',
        totalPending: 'Pendente',
        totalBlocked: 'Bloqueado',
        deviceTypes: 'Tipos de dispositivo',
        totalActivePacks: 'Pacotes de licença',
        totalNumLicenses: '# total de licenças',
        nextToExpire: 'Próxima data de expiração',
        totalInvitedUsers: 'Usuários convidados',
        totalUsedLicenses: 'Licenças usadas',
        totalAvailableLicenses: 'Licenças disponíveis',
    },
    es: {
        extendExistingLicensePacks: 'Ampliar paquetes de licencias existentes',
        dashLicenseInfo: 'Información de licencias',
        userStatus: 'Estado del usuario',
        totalUsers: 'Número total de usuarios',
        totalActive: 'Activo',
        totalPending: 'Pendiente',
        totalBlocked: 'Bloqueado',
        deviceTypes: 'Tipos de dispositivos',
        totalActivePacks: 'Paquetes de licencias',
        totalNumLicenses: '# total de licencias',
        nextToExpire: 'Próxima fecha de expiración',
        totalInvitedUsers: 'Usuarios invitados',
        totalUsedLicenses: 'Licencias usadas',
        totalAvailableLicenses: 'Licencias disponibles',
    }
}

const general = {
    en: {
        never: 'never',
        optional: 'Optional',
        domain: 'Domain',
        profileUpdateSuccessful: 'Profile Updated Successfully',
        profileUpdateFailed: 'Unable to update profile',
        loading: 'Loading...',
        password: 'Password',
        typeCurrentPword: 'Type current password',
        newPassword: 'New password',
        repeatNewPassword: 'Repeat new password',
        noDataAvailable: 'No Data Available',
        failedAttempts: 'Failed attempts',
        successfulAttempts: 'Successful attempts',
        theFollowingAdminsWill: 'The following admins will be',
        theFollowingUsersWill: 'The following users will be',
        theFollowingTeamsWill: 'The following companies will be',
        theFollowingDevicesWill: 'The following devices will be',
        theFollowingAssociationsWill: 'The following association will be',
        usernameNotEmail: 'This account is not registered using a valid e-mail address!',
        noUserSelected: 'No user selected',
        lessThanMinuteAgo: 'Less than a minute ago',
        hoursAnd: ' hours and ',
        hourAnd: ' hour and ',
        minutesAnd: ' minutes and ',
        minuteAnd: ' minute and ',
        daysAgo: ' days ago',
        dayAgo: ' day ago',
        minutesAgo: ' minutes ago',
        minuteAgo: ' minute ago',
        osVersion: 'OS Version',
        appVersion: 'App Version',
        osID: 'OS',
        comingSoon: 'coming soon',
        support: 'Support',
        today: 'Today',
        thisWeek: 'This week',
        thisMonth: 'This month',
        longTimeAgo: 'A long time ago'
    },
    pt: {
        never: 'nunca',
        optional: 'Opcional',
        domain: 'Domínio',
        profileUpdateSuccessful: 'Atualização de perfil bem sucedida',
        profileUpdateFailed: 'Não foi possível atualizar o perfil',
        loading: 'Carregando...',
        password: 'Senha',
        typeCurrentPword: 'Digite senha atual',
        newPassword: 'Nova senha',
        repeatNewPassword: 'Repita nova senha',
        noDataAvailable: 'Dados inexistentes',
        failedAttempts: 'Tentativas mal-sucedidas',
        successfulAttempts: 'Tentativas bem sucedidas',
        theFollowingAdminsWill: 'Os seguintes administradores serão',
        theFollowingUsersWill: 'Os seguintes usuários serão',
        theFollowingTeamsWill: 'Os seguintes times serão',
        theFollowingDevicesWill: 'Os seguintes dispositivos serão',
        theFollowingAssociationsWill: 'A seguinte associação será',
        usernameNotEmail: 'Esta conta não foi criada usando um endereço de e-mail válido!',
        noUserSelected: 'Nenhum usuário selecionado',
        osID: 'SO',
        osVersion: 'Versão SO',
        appVersion: 'Versão App',
        minutesAgo: ' minutos atrás',
        minuteAgo: ' minuto atrás',
        daysAgo: ' dias atrás',
        dayAgo: ' dia atrás',
        hoursAnd: ' horas e ',
        hourAnd: ' hora e ',
        minutesAnd: ' minutos e ',
        minuteAnd: ' minuto e ',
        lessThanMinuteAgo: 'A menos de um minuto atrás',
        comingSoon: 'disponível em breve',
        support: 'Suporte',
        today: 'Hoje',
        thisWeek: 'Esta semana',
        thisMonth: 'Este mês',
        longTimeAgo: 'Há muito tempo'
    },
    es: {
        never: 'nunca',
        optional: 'Opcional',
        domain: 'Dominio',
        profileUpdateSuccessful: 'Perfil actualizado con éxito',
        profileUpdateFailed: 'No se pudo actualizar el perfil',
        loading: 'Cargando...',
        password: 'Contraseña',
        typeCurrentPword: 'Escriba la contraseña actual',
        newPassword: 'Nueva contraseña',
        repeatNewPassword: 'Repetir nueva contraseña',
        noDataAvailable: 'No hay datos disponibles',
        failedAttempts: 'Intentos fallidos',
        successfulAttempts: 'Intentos exitosos',
        theFollowingAdminsWill: 'Los siguientes administradores serán',
        theFollowingUsersWill: 'Los siguientes usuarios serán',
        theFollowingTeamsWill: 'Los siguientes equipos serán',
        theFollowingDevicesWill: 'Los siguientes dispositivos serán',
        theFollowingAssociationsWill: 'La siguiente asociación será',
        usernameNotEmail: '¡Esta cuenta no está registrada con una dirección de correo electrónico válida!',
        noUserSelected: 'No se ha seleccionado ningún usuario',
        osID: 'SO',
        osVersion: 'Versión SO',
        appVersion: 'Versión de la aplicación',
        minutesAgo: ' minutos atrás',
        minuteAgo: ' minuto atrás',
        daysAgo: ' días atrás',
        dayAgo: ' día atrás',
        hoursAnd: ' horas y ',
        hourAnd: ' hora y ',
        minutesAnd: ' minutos y ',
        minuteAnd: ' minuto y ',
        lessThanMinuteAgo: 'Hace menos de un minuto',
        comingSoon: 'próximamente',
        support: 'Soporte',
        today: 'Hoy',
        thisWeek: 'Esta semana',
        thisMonth: 'Este mes',
        longTimeAgo: 'Hace mucho tiempo'
    }
}

const settings = {
    en: {
        mutualAuth: 'Mutual Authentication',
        clientCertChecking: 'User verification on client certificate',
        tlsDomainSettings: 'TLS/Domain Settings',
        api_url: 'API Prefix',
        aux_url: 'AUX Prefix',
        sip_url: 'SIP Prefix',
        vault_url: 'VAULT Prefix',
        emp_url: 'EMP Prefix',
        my_url: 'MY Prefix',
        generalSettings: 'General Settings',
        messageSettings: 'Message Settings',
        callingSettings: 'Call Settings',
        attachmentSettings: 'Attachment Settings',
        msgAttCallSettings: 'Message, Attachment and Call Settings',
        securitySettings: 'Security Settings',
        crashReporting: 'Crash reports',
        mdsEnabled: 'Multiple Device Support',
        niapMode: 'NIAP compliant mode',
        emailingEnabled: 'Use e-mail notifications',
        registrationEnabled: 'Allow outside users to register new companies',
        adminEMPTokenExpiration: 'Session expiration (in hours) for EMP',
        adminRegTokenExpiration: 'Token expiration (in minutes) for admin registrations',
        adminPwordResetTokenExpiration: 'Token expiration (in minutes) for admin password resets',
        userRegTokenExpiration: 'Token expiration (in minutes) for user registrations',
        userPwordResetTokenExpiration: 'Token expiration (in minutes) for user password resets',
        userInviteExpiration: 'User Invite Expiration (in days)',
        userInviteLength: 'Length of User Invite Tokens (in characters)',
        otpLength: 'One Time Password length (in characters)',
        otpTTL: 'One Time Password expiration (in minutes)',
        messageTTL: 'TTL (in seconds) for messages',
        attachmentTTL: 'TTL (in minutes) for attachments',
        rateLimitAttachments: 'Rate Limit for attachments',
        rateLimitMessages: 'Rate Limit for messages',
        rateLimitCalling: 'Rate Limit for calls',
        callTimeoutDefault: 'Default call timeout (in seconds)',
        callTimeoutMax: 'Maximum call timeout (in seconds)',
        callTimeoutMin: 'Minimum call timeout (in seconds)',
        sipReregisterInterval: 'SIP re-register interval (in seconds)',
        turnPassword: 'TURN/STUN password',
        turnUrl: 'TURN/STUN URL',
        turnUsername: 'TURN/STUN username',
        pinLock: 'Pin-lock',
        adminMaxNumberAdminSigninAttempts: 'Maximum number of admin sign-in attempts',
        userMaxNumberUserSigninAttempts: 'Maximum number of user sign-in attempts',
        repeatedAdminPwordHistory: 'Repeated administrator password history',
        repeatedUserPwordHistory: 'Repeated user password history',
    },
    pt: {
        mutualAuth: 'Autenticação Mútua',
        clientCertChecking: 'Verificação do usuário no certificado do cliente',
        tlsDomainSettings: 'Configurações TLS/Domínio',
        api_url: 'Prefixo API',
        aux_url: 'Prefixo AUX',
        sip_url: 'Prefixo SIP',
        vault_url: 'Prefixo VAULT',
        emp_url: 'Prefixo EMP',
        my_url: 'Prefixo MY',
        generalSettings: 'Configurações Gerais',
        messageSettings: 'Configuração de Mensagens',
        callingSettings: 'Configuração de Chamadas',
        attachmentSettings: 'Configuração de Anexos',
        msgAttCallSettings: 'Configuração de Mensagens, Anexos e Chamadas',
        securitySettings: 'Configurações de Segurança',
        crashReporting: 'Relatório de problemas',
        mdsEnabled: 'Suporte a múltiplos dispositivos',
        niapMode: 'NIAP compliant mode',
        emailingEnabled: 'Usar notificações via e-mail',
        registrationEnabled: 'Permitir usuários externos criarem times',
        adminEMPTokenExpiration: 'Expiração da sessão (em horas) para EMP',
        adminRegTokenExpiration: 'Expiração do código para registro de administradores (em minutos) ',
        adminPwordResetTokenExpiration: 'Expiração do código para redefinição de senhas de administradores (em minutos) ',
        userRegTokenExpiration: 'Expiração (em minutos) do token para registro de usuários',
        userPwordResetTokenExpiration: 'Expiração (em minutos) do token para redefinição de senha de usuários',
        userInviteExpiration: 'Expiração de convite para usuário (em dias)',
        userInviteLength: 'Tamanho do código de convite de usuário (em caracteres)',
        otpLength: 'Tamanho da senha de uso único (em caracteres)',
        otpTTL: 'Expiração da senha de uso único (em caracteres)',
        messageTTL: 'Tempo de vida (em segundos) para mensagens',
        attachmentTTL: 'TTL (em minutos) para anexos',
        rateLimitAttachments: 'Rate Limit for attachments',
        rateLimitCalling: 'Limite de acesso para ligações',
        rateLimitMessages: 'Limite de acesso para mensagens',
        callTimeoutDefault: 'Timeout padrão para ligações (em segundos)',
        callTimeoutMax: 'Timeout para ligações máximo (em segundos)',
        callTimeoutMin: 'Timeout para ligações mínimo (em segundos)',
        sipReregisterInterval: 'SIP re-register interval (em segundos)',
        turnPassword: 'TURN/STUN password',
        turnUrl: 'TURN/STUN URL',
        turnUsername: 'TURN/STUN username',
        pinLock: 'Pin-lock',
        adminMaxNumberAdminSigninAttempts: 'Número máximo de tentativas de acesso de administradores',
        userMaxNumberUserSigninAttempts: 'Número máximo de tentativas de acesso de usuário',
        repeatedAdminPwordHistory: 'Histórico de senhas repetidas de administradores',
        repeatedUserPwordHistory: 'Histórico de senhas repetidas de usuários',
    },
    es: {
        mutualAuth: 'Autenticación mutua',
        clientCertChecking: 'Verificación del usuario en el certificado del cliente',
        tlsDomainSettings: 'Configuraciones TLS/Dominio',
        api_url: 'Prefijo API',
        aux_url: 'Prefijo AUX',
        sip_url: 'Prefijo SIP',
        vault_url: 'Prefijo VAULT',
        emp_url: 'Prefijo EMP',
        my_url: 'Prefijo MY',
        generalSettings: 'Configuraciones generales',
        messageSettings: 'Configuración de mensajes',
        callingSettings: 'Configuración de llamadas',
        attachmentSettings: 'Configuración de archivos adjuntos',
        msgAttCallSettings: 'Configuración de mensajes, archivos adjuntos y llamadas',
        securitySettings: 'Configuraciones de seguridad',
        crashReporting: 'Informes de fallos',
        mdsEnabled: 'Soporte para múltiples dispositivos',
        niapMode: 'Modo compatible con NIAP',
        emailingEnabled: 'Usar notificaciones por correo electrónico',
        registrationEnabled: 'Allow outside users to register new teams',
        adminEMPTokenExpiration: 'Expiración de sesión (en horas) para EMP',
        adminRegTokenExpiration: 'Expiración del token (en minutos) para registros de administradores',
        adminPwordResetTokenExpiration: 'Expiración del token (en minutos) para restablecimientos de contraseña de administradores',
        userRegTokenExpiration: 'Expiración del token (en minutos) para registros de usuarios',
        userPwordResetTokenExpiration: 'Expiración del token (en minutos) para restablecimientos de contraseña de usuarios',
        userInviteExpiration: 'Expiración de la invitación de usuario (en dias)',
        userInviteLength: 'Longitud de la contraseña de invitación de usuario (en caracteres)',
        otpLength: 'Longitud de la contraseña de un solo uso (en caracteres)',
        otpTTL: 'Expiración de la contraseña de un solo uso (en minutos)',
        messageTTL: 'TTL (en segundos) para mensajes',
        attachmentTTL: 'TTL (en minutos) para archivos adjuntos',
        rateLimitAttachments: 'Límite de tasa para archivos adjuntos',
        rateLimitMessages: 'Límite de tasa para mensajes',
        rateLimitCalling: 'Límite de tasa para llamadas',
        callTimeoutDefault: 'Tiempo de espera predeterminado para llamadas (en segundos)',
        callTimeoutMax: 'Tiempo de espera máximo para llamadas (en segundos)',
        callTimeoutMin: 'Tiempo de espera mínimo para llamadas (en segundos)',
        sipReregisterInterval: 'Intervalo de re-registro SIP (en segundos)',
        turnPassword: 'Contraseña TURN/STUN',
        turnUrl: 'URL TURN/STUN',
        turnUsername: 'Nombre de usuario TURN/STUN',
        pinLock: 'Bloqueo por pin',
        adminMaxNumberAdminSigninAttempts: 'Número máximo de intentos de inicio de sesión de administrador',
        userMaxNumberUserSigninAttempts: 'Número máximo de intentos de inicio de sesión de usuario',
        repeatedAdminPwordHistory: 'Historial de contraseñas repetidas de administrador',
        repeatedUserPwordHistory: 'Historial de contraseñas repetidas de usuario',
    }
}

const publicKeys = {
    en: {
        verifyAccount: 'Verify your e-mail address',
        registerNow: 'Register now!',
        register: 'Register',
        confirmRegistration: 'Confirm registration',
        toCreateAccount: 'To create an account, please choose a name for your user and company:',
        readTermsOfService: 'I have read and agreed to the',
        termsOfService: 'terms of service',
        alreadyHaveAccount: 'Already have an account?',
        login: 'Login',
        forgotPassword: 'Forgot Password?',
        notYetHaveAccount: 'Do you not yet have an account?',
        backToSignIn: 'Go Back To Sign-In Page',
        accountVerificationRequest: 'Please click on the button below to confirm your e-mail address and activate your account!',
        accountVerificationSuccessful: 'Account verification successful! \nYou may now proceed to the sign-in page and start using the platform!',
        email: 'Username (must be a valid e-mail address)',
        passwordResetSuccessful: `Password reset successfully sent. The sent password reset
        link will expire in an hour. Please check your email for further instructions.`,
        resetYourPassword: 'Reset your Password',
        defineYourPassword: 'Reset your Password',
    },
    pt: {
        verifyAccount: 'Verifique sua conta',
        registerNow: 'Registre sua conta agora!',
        register: 'Registrar',
        confirmRegistration: 'Confirme seu registro',
        toCreateAccount: 'Para criar uma conta, escolha um nome para seu usuário e time:',
        readTermsOfService: 'Eu li e concordo com os',
        termsOfService: 'termos de serviço',
        alreadyHaveAccount: 'Já possui uma conta?',
        login: 'Acessar',
        forgotPassword: 'Esqueceu sua senha?',
        notYetHaveAccount: 'Você ainda não tem uma conta?',
        backToSignIn: 'Voltar a página de acesso',
        accountVerificationRequest: 'Por favor clique no botão abaixo para confirmar seu e-mail e ativar sua conta!',
        accountVerificationSuccessful: 'Verificação de conta bem sucedida! Você agora já pode ir a página de acesso e começar a usar a plataforma!',
        email: 'Nome do usuário (deve ser um e-mail válido)',
        passwordResetSuccessful: `E-mail para redefinição da senha enviado com sucesso. Este e-mail é válido 
        por uma hora. Por favor leia seu e-mail para mais instruções.`,
        resetYourPassword: 'Redefina sua senha',
        defineYourPassword: 'Defina sua senha',
    },
    es: {
        verifyAccount: 'Verifica tu dirección de correo electrónico',
        registerNow: '¡Regístrate ahora!',
        register: 'Registrar',
        confirmRegistration: 'Confirmar registro',
        toCreateAccount: 'Para crear una cuenta, elija un nombre para su usuario y equipo:',
        readTermsOfService: 'He leído y acepto los',
        termsOfService: 'términos de servicio',
        alreadyHaveAccount: '¿Ya tienes una cuenta?',
        login: 'Iniciar sesión',
        forgotPassword: '¿Olvidaste tu contraseña?',
        notYetHaveAccount: '¿Aún no tienes una cuenta?',
        backToSignIn: 'Volver a la página de inicio de sesión',
        accountVerificationRequest: '¡Haga clic en el botón de abajo para confirmar su dirección de correo electrónico y activar su cuenta!',
        accountVerificationSuccessful: '¡Verificación de cuenta exitosa! Ahora puede ir a la página de inicio de sesión y comenzar a usar la plataforma.',
        email: 'Nombre de usuario (debe ser una dirección de correo electrónico válida)',
        passwordResetSuccessful: `Restablecimiento de contraseña enviado con éxito. El enlace de restablecimiento de contraseña enviado expirará en una hora. Por favor, revise su correo electrónico para más instrucciones.`,
        resetYourPassword: 'Restablece tu contraseña',
        defineYourPassword: 'Establece tu contraseña',
    }
}

const actionsStatus = {
    en: {
        toggleMenu: 'Toggle side menu',
        confirm: 'Confirm',
        or: 'or',
        paginationRowsPerPage: 'Items per page',
        paginationOf: 'of',
        na: 'Not Available',
        save: 'Save',
        upload: 'Upload',
        close: 'Close',
        back: 'Back',
        next: 'Next',
        edit: 'Edit',
        add: 'Add',
        signOut: 'Sign out',
        active: 'Active',
        accepted: 'Accepted',
        pending: 'Pending',
        actions: 'Actions',
        error: 'Error',
        empty: 'x',
        search: 'Search',
        delete: 'Delete',
        deleteDevice: 'Delete Device',
        deleted: 'deleted',
        dismiss: 'Dismiss',
        bulkDelete: 'Bulk Delete',
        bulkMove: 'Bulk Move',
        block: 'Block',
        blocked: 'blocked',
        moved: 'moved',
        blocked2: 'Blocked',
        activate: 'Activate',
        activated: 'activated',
        terminate: 'Terminate',
        viewEdit: 'View/Edit',
        manage: 'Manage',
        invite: 'Invite',
        purchase: 'Purchase',
        clear: 'Clear',
        expired: 'expired',
        extended: 'extended',
        backUserManagement: '< Back to User Management',
        backAdminManagement: '< Back to Admin Management',
        backTeamManagement: '< Back to Company Management',
        backLicenseManagement: '< Back to License Management',
        create: 'Create',
    },
    pt: {
        toggleMenu: 'Ativar menu lateral',
        confirm: 'Confirmar',
        or: 'ou',
        paginationRowsPerPage: 'Itens por página',
        paginationOf: 'de',
        na: 'Indisponível',
        save: 'Salvar',
        upload: 'Carregar',
        close: 'Fechar',
        back: 'Voltar',
        next: 'Próxima',
        edit: 'Editar',
        add: 'Adicionar',
        signOut: 'Sair',
        active: 'Ativo',
        accepted: 'Aceito',
        pending: 'Pendente',
        actions: 'Ações',
        error: 'Erro',
        empty: 'x',
        search: 'Busca',
        delete: 'Remover',
        deleteDevice: 'Remover Dispositivo',
        deleted: 'removidos',
        dismiss: 'Fechar',
        bulkDelete: 'Remover em Grupo',
        bulkMove: 'Mover em Grupo',
        block: 'Bloquear',
        blocked: 'bloqueados',
        moved: 'movidos',
        blocked2: 'Bloqueado',
        activate: 'Ativar',
        activated: 'ativados',
        terminate: 'Encerrar Conta',
        viewEdit: 'Visualizar/Editar',
        manage: 'Gerenciar',
        invite: 'Convidar',
        purchase: 'Comprar',
        clear: 'Limpar',
        expired: 'expiradas',
        extended: 'extendida',
        backUserManagement: '< Voltar ao Painel de usuários',
        backAdminManagement: '< Voltar ao Painel de Administradores',
        backTeamManagement: '< Voltar ao Painel de Times',
        backLicenseManagement: '< Voltar ao Painel de Licenças',
        create: 'Criar',
    },
    es: {
        toggleMenu: 'Activar menú lateral',
        confirm: 'Confirmar',
        or: 'o',
        paginationRowsPerPage: 'Elementos por página',
        paginationOf: 'de',
        na: 'No disponible',
        save: 'Guardar',
        upload: 'Subir',
        close: 'Cerrar',
        back: 'Atrás',
        next: 'Siguiente',
        edit: 'Editar',
        add: 'Añadir',
        signOut: 'Cerrar sesión',
        active: 'Activo',
        accepted: 'Aceptado',
        pending: 'Pendiente',
        actions: 'Acciones',
        error: 'Error',
        empty: 'x',
        search: 'Buscar',
        delete: 'Eliminar',
        deleteDevice: 'Eliminar dispositivo',
        deleted: 'eliminado',
        dismiss: 'Descartar',
        bulkDelete: 'Eliminar en grupo',
        bulkMove: 'Mover en grupo',
        block: 'Bloquear',
        blocked: 'bloqueado',
        moved: 'movidos',
        blocked2: 'Bloqueado',
        activate: 'Activar',
        activated: 'activado',
        terminate: 'Terminar',
        viewEdit: 'Ver/Editar',
        manage: 'Gestionar',
        invite: 'Invitar',
        purchase: 'Comprar',
        clear: 'Limpiar',
        expired: 'expirado',
        extended: 'estendida',
        backUserManagement: '< Volver a la gestión de usuarios',
        backAdminManagement: '< Volver a la gestión de administradores',
        backTeamManagement: '< Volver a la gestión de equipos',
        backLicenseManagement: '< Volver a la gestión de licencias',
        create: 'Crear'
    }
}

const headers = {
    en: {
        quickAction: 'Quick Action',
        listTeamLicenses: 'List of licenses associated with this company',
        listTeamAdmins: 'List of admins with access to this company',
        settings: 'Settings',
        general: 'General',
        dashboard: 'Dashboard',
        accessLogs: 'Access Logs',
        greeting: 'Welcome to the EMP',
    },
    pt: {
        quickAction: 'Ação Rápida',
        listTeamLicenses: 'Lista de licenças associadas a este time',
        listTeamAdmins: 'Lista de administradores com acesso a este time',
        settings: 'Configurações',
        general: 'Geral',
        dashboard: 'Painel de Controle',
        accessLogs: 'Registros de Acesso',
        greeting: 'Bem vindo ao EMP',
        teams: 'Times',
        team: 'Time',
        manageTeams: 'Gerenciar Times',
        teamProfile: 'Perfil do Time',
        teamList: 'Lista de times',
        teamListFor: 'Lista de times para',
    },
    es: {
        quickAction: 'Acción Rápida',
        listTeamLicenses: 'Lista de licencias asociadas a este equipo',
        listTeamAdmins: 'Lista de administradores con acceso a este equipo',
        settings: 'Configuraciones',
        general: 'General',
        dashboard: 'Tablero',
        accessLogs: 'Registros de acceso',
        greeting: 'Bienvenido al EMP',
    }
}

const associations = {
    en: {
        associationStatus: 'Association Status',
        associationOpen: 'Open',
        associationClosed: 'Closed',
        enableClosedComms: 'Enable Close Communications',
        selectAssociation: 'Choose an Association',
        availableAssociations: 'Available associations',
        associationList: 'Company Associations',
        associations: 'Associations',
        listTeamConnections: 'List of companies to which connections are allowed',
        manageAssociations: 'Manage Company Associations',
        createAssociation: 'Create Association',
        editAssociation: 'Edit Association',
        noTeamsAvailable: 'No Companies Available',
        noAssociationsAvailable: 'No Associations Available',
        deleteAssociation: 'Delete Association',
    },
    pt: {
        associationStatus: 'Status de Associação',
        associationOpen: 'Aberta',
        associationClosed: 'Fechada',
        enableClosedComms: 'Ativar Comunicação Fechada',
        selectAssociation: 'Selecione uma Associação',
        availableAssociations: 'Associações disponíveis',
        associationList: 'Associações entre times',
        associations: 'Associações',
        listTeamConnections: 'Lista de times a quem conexões são permitidas',
        manageAssociations: 'Gerenciar Associação de Times',
        createAssociation: 'Criar Associação',
        editAssociation: 'Editar Associação',
        noTeamsAvailable: 'Não há times disponíveis',
        noAssociationsAvailable: 'Não há associações disponíveis',
        deleteAssociation: 'Remover Associação',
    },
    es: {
        associationStatus: 'Estado de la asociación',
        associationOpen: 'Abierta',
        associationClosed: 'Cerrada',
        enableClosedComms: 'Habilitar comunicaciones cerradas',
        selectAssociation: 'Elegir una asociación',
        availableAssociations: 'Asociaciones disponibles',
        associationList: 'Asociaciones de equipo',
        associations: 'Asociaciones',
        listTeamConnections: 'Lista de equipos a los que se permiten conexiones',
        manageAssociations: 'Gestionar asociaciones de equipos',
        createAssociation: 'Crear asociación',
        editAssociation: 'Editar asociación',
        noTeamsAvailable: 'No hay equipos disponibles',
        noAssociationsAvailable: 'No hay asociaciones disponibles',
        deleteAssociation: 'Eliminar asociación',
    }
}

const appSettings = {
    en: {
        apps: 'Applications',
        appSettings: 'Application Management',
        androidSettings: 'Android Settings',
        ecsSettings: 'ECS Settings',
        iosSettings: 'iOS Settings',
        windowsSettings: 'Windows Settings',
        minVersion: 'Minimum Supported Version',
        currentVersion: 'Last Released Version',
        storeURL: 'URL Download Path',
    },
    pt: {
        apps: 'Aplicativos',
        appSettings: 'Gerenciamento dos Aplicativos',
        androidSettings: 'Configuração Android',
        ecsSettings: 'Configuração ECS',
        iosSettings: 'Configuração iOS',
        windowsSettings: 'Configuração Windows',
        minVersion: 'Versão Mínima Suportada',
        currentVersion: 'Última Versão Lançada',
        storeURL: 'URL Para Baixar',
    },
    es: {
        apps: 'Aplicaciones',
        appSettings: 'Gestión de aplicaciones',
        androidSettings: 'Configuraciones de Android',
        ecsSettings: 'Configuraciones de ECS',
        iosSettings: 'Configuraciones de iOS',
        windowsSettings: 'Configuraciones de Windows',
        minVersion: 'Versión mínima compatible',
        currentVersion: 'Última versión lanzada',
        storeURL: 'Ruta de descarga de URL',
    }
}

const passwordReset = {
    en: {
        choosePword: 'Please choose a password:',
        chooseNewPword: 'Please choose a new password:',
        pwordReset: 'Reset Password',
        changePword2: 'Or request password reset e-mail',
        changePword3: 'Send Password Reset E-mail',
        changePword4: 'Request Password Reset E-mail',
        changePword5: 'will receive a password reset e-mail.',
        changePword6: 'The e-mail will be sent to',
    },
    pt: {
        choosePword: 'Por favor escolha uma senha:',
        chooseNewPword: 'Por favor escolha uma nova senha:',
        pwordReset: 'Redefinir Senha',
        changePword2: 'Ou solicitar envio de e-mail para redefinição',
        changePword3: 'Enviar E-mail para Redefinição de Senha',
        changePword4: 'Solicitar e-mail para redefinição de senha',
        changePword5: 'vai receber um e-mail para redefinição de senha.',
        changePword6: 'O e-mail será enviado para',
    },
    es: {
        choosePword: 'Por favor elija una contraseña:',
        chooseNewPword: 'Por favor elija una nueva contraseña:',
        pwordReset: 'Restablecer contraseña',
        changePword2: 'O solicite el envío de un correo electrónico para restablecer la contraseña',
        changePword3: 'Enviar correo electrónico de restablecimiento de contraseña',
        changePword4: 'Solicitar correo electrónico de restablecimiento de contraseña',
        changePword5: 'recibirá un correo electrónico para restablecer la contraseña.',
        changePword6: 'El correo electrónico se enviará a',
    }
}

const profileInfo = {
    en: {
        city: 'City',
        institution: 'Institution',
        profile: 'Profile',
        profileFor: 'Profile for',
        profileDetails: 'Profile Details',
        createdOn: 'Created on',
        myProfile: 'My Profile',
        expiration: 'Expiration',
        lastSeen: 'Last seen online',
        ipAddr: 'IP Address',
        name: 'Name',
        description: 'Description',
        firstName: 'First Name',
        lastName: 'Last Name',
        username: 'Username',
        language: 'Language',
        country: 'Country',
        notes: 'Notes',
        financeContact: 'Finance Contact E-mail',
        opsContact: 'Operations Contact E-mail',
        supportContact: 'Support Contact E-mail',
        timezone: 'Time zone',
        location: 'Location',
        dateTime: 'Date/Time',
        role: 'Role',
        access: 'Access level',
    },
    pt: {
        city: 'Cidade',
        institution: 'Instituição',
        profile: 'Perfil',
        profileFor: 'Perfil de',
        profileDetails: 'Detalhes do Perfil',
        createdOn: 'Criado em',
        myProfile: 'Meu Perfil',
        expiration: 'Expiração',
        lastSeen: 'Último acesso',
        ipAddr: 'Endereço IP',
        name: 'Nome',
        description: 'Descrição',
        firstName: 'Nome',
        lastName: 'Sobrenome',
        username: 'Usuário',
        language: 'Idioma',
        country: 'País',
        notes: 'Observações',
        financeContact: 'E-mail do Contato Financeiro',
        opsContact: ' E-mail do Contato de Operações',
        supportContact: ' E-mail do Contato de Suporte',
        timezone: 'Fuso-horário',
        location: 'Localização',
        dateTime: 'Data/Hora',
        role: 'Papel',
        access: 'Nível de acesso',
    },
    es: {
        city: 'Ciudad',
        institution: 'Institución',
        profile: 'Perfil',
        profileFor: 'Perfil para',
        profileDetails: 'Detalles del perfil',
        createdOn: 'Creado en',
        myProfile: 'Mi perfil',
        expiration: 'Expiración',
        lastSeen: 'Visto por última vez',
        ipAddr: 'Dirección IP',
        name: 'Nombre',
        description: 'Descripción',
        firstName: 'Nombre de pila',
        lastName: 'Apellido',
        username: 'Nombre de usuario',
        language: 'Idioma',
        country: 'País',
        notes: 'Notas',
        financeContact: 'E-mail Contacto financiero',
        opsContact: ' E-mail Contacto de operaciones',
        supportContact: ' E-mail Contacto de soporte',
        timezone: 'Zona horaria',
        location: 'Ubicación',
        dateTime: 'Fecha/Hora',
        role: 'Rol',
        access: 'Nivel de acceso',
    }
}

const admins = {
    en: {
        resendConfirmation: 'Re-send confirmation',
        licenseActivationSuccessful: 'License activated successfully!',
        insertActivationToken: 'Insert Activation Token',
        validToken: 'Valid Token',
        testToken: 'Validate Token',
        invalidToken: 'Invalid Token',
        superAdmin: 'Super Admin',
        administrators: 'Administrators',
        admin: 'Admin',
        adminList: 'Admin List',
        adminProfile: 'Admin Profile',
        manageAdmins: 'Manage Admins',
        createLicenseSuccessful: 'License purchased successfully!',
        createTeam: 'Create Company',
        createTeamSuccessful: 'Company Created Successfully!',
        createTeamInfo: 'Company Information',
        createAdmin: 'Create Administrator',
        createAdminFill: 'Please fill in information below',
        createAdminSuccessful: 'Admin User Created Successfully!',
        createAdminInfo: 'Admin information',
        createAdminAsSuperAdmin: 'Create as Super-Admin?',
        createAdminAssignedTeams: 'Assigned Companies',
        createAdminRegistrationMethod: 'Registration Method',
        createAdminTooltip: `
        <b>Define password</b> - the admin user will be automatically activated.<br /> <br />
        <b>Send invite via e-mail (when emailing is enabled)</b> - the created admin will have to manually
        activate the account by clicking on the link sent to the chosen e-mail address.`,
        createAdminSendInvite: ' Send Invite via Email',
        createAdminDefinePword: 'Define Password',
        chooseTeam: 'Choose your company',
        teams: 'Companies',
        team: 'Company',
        manageTeams: 'Manage Companies',
        teamProfile: 'Company Profile',
        teamList: 'Company List',
        teamListFor: 'Company list for',
    },
    pt: {
        resendConfirmation: 'Reenviar confirmação',
        licenseActivationSuccessful: 'Licença ativada com sucesso!',
        insertActivationToken: 'Insira o Token de Ativação',
        validToken: 'Token Válido',
        testToken: 'Validar Token',
        invalidToken: 'Token Inválido',
        superAdmin: 'Super Administrador',
        administrators: 'Administradores',
        admin: 'Administrador',
        adminList: 'Lista de Administradores',
        adminProfile: 'Perfil do Administrador',
        manageAdmins: 'Gerenciar Administradores',
        createTeam: 'Criar Time',
        createTeamInfo: 'Informação sobre o time',
        createTeamSuccessful: 'Time criado com sucesso!',
        createLicenseSuccessful: 'Licença adquirida com sucesso!',
        createAdmin: 'Criar Administrador',
        createAdminFill: 'Por favor preencha a informação abaixo',
        createAdminSuccessful: 'Usuário administrador criado com sucesso!',
        createAdminInfo: 'Informação do administrador',
        createAdminAsSuperAdmin: 'Create as Super-Admin?',
        createAdminAssignedTeams: 'Times gerenciados',
        createAdminRegistrationMethod: 'Método de registro',
        createAdminTooltip: `
        <b>Definir senha</b> - o administrador será ativado automaticamente.<br /> <br />
        <b>Enviar convite via e-mail (quando e-mails estão ativados)</b> - o administrador deverá manualmente ativar sua conta
        clicando no link enviado para o e-mail escolhido.`,
        createAdminSendInvite: 'Enviar convite via e-mail',
        createAdminDefinePword: 'Definir senha',
        chooseTeam: 'Escolha seu time',
    },
    es: {
        resendConfirmation: 'Reenviar confirmación',
        licenseActivationSuccessful: '¡Licencia activada con éxito!',
        insertActivationToken: 'Inserte el Token de Activación',
        validToken: 'Token Válido',
        testToken: 'Validar Token',
        invalidToken: 'Token Inválido',
        superAdmin: 'Super Administrador',
        administrators: 'Administradores',
        admin: 'Administrador',
        adminList: 'Lista de Administradores',
        adminProfile: 'Perfil del Administrador',
        manageAdmins: 'Gestionar Administradores',
        createLicenseSuccessful: '¡Licencia adquirida con éxito!',
        createTeam: 'Crear equipo',
        createTeamSuccessful: '¡Equipo creado con éxito!',
        createTeamInfo: 'Información del equipo',
        createAdmin: 'Crear administrador',
        createAdminFill: 'Por favor complete la información a continuación',
        createAdminSuccessful: '¡Usuario administrador creado con éxito!',
        createAdminInfo: 'Información del administrador',
        createAdminAsSuperAdmin: '¿Crear como Super-Admin?',
        createAdminAssignedTeams: 'Equipos asignados',
        createAdminRegistrationMethod: 'Método de registro',
        createAdminTooltip: `
            <b>Definir contraseña</b> - el usuario administrador será activado automáticamente.<br /> <br />
            <b>Enviar invitación por correo electrónico (cuando se habilitan los correos electrónicos)</b> - el administrador creado deberá activar manualmente la cuenta haciendo clic en el enlace enviado a la dirección de correo electrónico elegida.`,
        createAdminSendInvite: 'Enviar invitación por correo electrónico',
        createAdminDefinePword: 'Definir contraseña',
        chooseTeam: 'Elija su equipo',
        teams: 'Equipos',
        team: 'Equipo',
        manageTeams: 'Gestionar equipos',
        teamProfile: 'Perfil del equipo',
        teamList: 'Lista de equipos',
        teamListFor: 'Lista de equipos para',
    }
}

export const messages = {
    en: {
        ...dashboard.en,
        ...licenses.en,
        ...domains.en,
        ...invites.en,
        ...teams.en,
        ...responseMessages.en,
        ...yup.en,
        ...users.en,
        ...general.en,
        ...settings.en,
        ...publicKeys.en,
        ...headers.en,
        ...actionsStatus.en,
        ...associations.en,
        ...appSettings.en,
        ...passwordReset.en,
        ...profileInfo.en,
        ...admins.en,
    },
    pt: {
        ...dashboard.pt,
        ...domains.pt,
        ...invites.pt,
        ...licenses.pt,
        ...teams.pt,
        ...responseMessages.pt,
        ...yup.pt,
        ...users.pt,
        ...general.pt,
        ...settings.pt,
        ...publicKeys.pt,
        ...headers.pt,
        ...actionsStatus.pt,
        ...associations.pt,
        ...appSettings.pt,
        ...passwordReset.pt,
        ...profileInfo.pt,
        ...admins.pt,
    },
    es: {
        ...dashboard.es,
        ...domains.es,
        ...invites.es,
        ...licenses.es,
        ...teams.es,
        ...responseMessages.es,
        ...yup.es,
        ...users.es,
        ...general.es,
        ...settings.es,
        ...publicKeys.es,
        ...headers.es,
        ...actionsStatus.es,
        ...associations.es,
        ...appSettings.es,
        ...passwordReset.es,
        ...profileInfo.es,
        ...admins.es,
    }
};